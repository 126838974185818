import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          At Hulchal.in, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to ensure its security.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect various types of information to provide and improve our services to you:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Personal Information:</strong> When you register on our site, subscribe to our newsletter, or fill out a form, we may collect personal details such as your name, email address, phone number, and other contact information.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Usage Data:</strong> We automatically collect information about how you interact with our website. This includes your IP address, browser type, operating system, pages visited, and the date and time of your visit.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Cookies:</strong> We use cookies to enhance your experience on our site. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enable the site's or service provider's systems to recognize your browser and capture and remember certain information.
        </Typography>
        <Typography variant="h6" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          The information we collect is used in the following ways:
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>To personalize your experience and respond to your individual needs.</li>
            <li>To improve our website and services based on the information and feedback we receive from you.</li>
            <li>To send periodic emails regarding updates, promotions, or other information related to our services.</li>
            <li>To administer contests, promotions, surveys, or other site features.</li>
          </ul>
        </Typography>
        <Typography variant="h6" gutterBottom>
          Protection of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We implement a variety of security measures to maintain the safety of your personal information. These include the use of secure servers, encryption, and regular security audits.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell, trade, or otherwise transfer your personal information to outside parties. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Third-Party Links
        </Typography>
        <Typography variant="body1" paragraph>
          Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Your Consent
        </Typography>
        <Typography variant="body1" paragraph>
          By using our site, you consent to our website's privacy policy.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Changes to Our Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          If we decide to change our privacy policy, we will post those changes on this page. Policy changes will apply only to information collected after the date of the change.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Contacting Us
        </Typography>
        <Typography variant="body1" paragraph>
          If there are any questions regarding this privacy policy, you may contact us using the information below:
        </Typography>
        <Typography variant="body1" paragraph>
          <address>
            Hulchal.in<br />
            Email: contact@hulchal.in<br />
            Phone: +91-9871729643
          </address>
        </Typography>
        <Typography variant="body1" paragraph>
          This policy was last modified on April-2024.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
