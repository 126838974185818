import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const AboutUs = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Hulchal.in, your one-stop destination for the latest news across all categories, heartwarming shayaris, and side-splitting jokes. Founded with the vision of bringing comprehensive and engaging content to readers, Hulchal.in is dedicated to delivering top-notch information and entertainment.
        </Typography>
        <Typography variant="body1" paragraph>
          At Hulchal.in, we understand the importance of staying informed in today's fast-paced world. Our team of dedicated journalists and content creators work tirelessly to provide you with accurate and up-to-date news from a wide array of categories including politics, sports, entertainment, technology, health, and more. We believe that an informed society is an empowered society, and our mission is to ensure that you never miss out on important events and trends.
        </Typography>
        <Typography variant="body1" paragraph>
          But life isn't just about staying informed; it's also about enjoying the lighter moments. That's why, in addition to our extensive news coverage, we offer a delightful collection of jokes and shayaris. Our jokes section is sure to bring a smile to your face, with a variety of humorous content that caters to all tastes. Whether you're looking for a quick laugh during a busy day or some light-hearted fun to share with friends and family, our jokes are here to brighten your day.
        </Typography>
        <Typography variant="body1" paragraph>
          Our Shayaris section, on the other hand, provides a touch of poetry and emotion. We curate beautiful and moving shayaris that touch the soul and resonate with the heart. Whether you are in the mood for love, reflection, or simply want to enjoy the beauty of words, our shayaris are the perfect escape.
        </Typography>
        <Typography variant="body1" paragraph>
          Hulchal.in is more than just a news website; it's a community. We believe in the power of connection and engagement. That's why we encourage our readers to share their thoughts, opinions, and feedback with us. Your voice matters, and we are committed to listening to our audience and continuously improving our platform.
        </Typography>
        <Typography variant="body1" paragraph>
          We are also proud to offer a user-friendly experience on our website. With a clean and intuitive design, navigating through our vast array of content is a breeze. Whether you're browsing on your computer, tablet, or smartphone, Hulchal.in is optimized for all devices, ensuring that you can access our content anytime, anywhere.
        </Typography>
        <Typography variant="body1" paragraph>
          Our commitment to quality extends beyond our content. We adhere to the highest standards of journalistic integrity and ethics. Our team verifies every piece of information before it is published, ensuring that you receive only accurate and reliable news. In a world where misinformation is rampant, we take our responsibility seriously and strive to be a trustworthy source of news and entertainment.
        </Typography>
        <Typography variant="body1" paragraph>
          Thank you for choosing Hulchal.in. We are honored to be your go-to source for news, jokes, and shayaris. We look forward to serving you with the best and most diverse range of content. Stay connected with us for daily updates, laughter, and inspiration. Together, let's create a vibrant and informed community.
        </Typography>
        <Typography variant="body1" paragraph>
          For any inquiries, feedback, or suggestions, feel free to reach out to us. We value your input and are always here to assist you.
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to the Hulchal.in family!
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutUs;
