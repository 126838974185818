import React, { useEffect, useState } from "react";
import NewsItems from './NewsItems';
import Spinner from './Spinner';
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const News = (props) => {
    const [articles, setArticles] = useState([]);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [pageSize, setPageSize] = useState(6);
    const [firstScroll, setFirstScroll] = useState(false);

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const updateNews = async () => {
        //let url = 'http://localhost/test/getnews.php';
        let url = 'https://news.g1tech.in/getnews.php';
        let data = {
            country: props.country,
            category: props.category,
            apiKey: props.apiKey,
            page: page,
            pageSize: 8
        };

        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
        try {
            let newsData = await fetch(url, options);
            let parsedData = await newsData.json();
            setArticles(articles.concat(parsedData));
            setTotalResults(parsedData.totalResults);
            setPage(page + 1)
            props.setProgress(100);
        } catch (error) {
            console.error('Error fetching news data:', error);
        }
    }

    useEffect(() => {
        document.title = `${Capitalize(props.category)} - HulChal.in`;        
        updateNews();
    }, []);

    const fetchMoreData = async () => {
        console.log('pagepage',page)
        if(page >= 1){
            setFirstScroll(true);
        }
        await updateNews();
    }

    return (
        <HelmetProvider>
        <>
        {<Helmet>
            <meta name="description" content={props.category} />
            <meta name="keywords" content={props.category.split(' ').join(', ')} />
        </Helmet>}
        <h2 className='text-center' style={{ margin: '10px' }}>Top Headlines - {Capitalize(props.category)}</h2>
        <InfiniteScroll
                dataLength={articles.length}
                next={fetchMoreData}
                hasMore={totalResults !== articles.length}
                loader={<Spinner />}
            >
                <div className="container" key="newsCont">        
                    <div className="row">
                    {firstScroll && (
                        <div id="container-e30d5d3677a61d31553e4ba9a52aae03"></div>
                    )}
                    {articles.map((element, index) => {
                        return (
                            <div className="col-md-3" key={element.url || `news_item_${index}`}>
                                <NewsItems
                                    key={`news_${element.id}`} 
                                    category={element.category} 
                                    title={element.title} 
                                    description={element.description} 
                                    imageUrl={!element.urlToImage ? "https://cdn.ndtv.com/common/images/ogndtv.png" : element.urlToImage} 
                                    newsUrl={element.url} 
                                    updateDate={element.updateDate} 
                                    auther={element.auther} 
                                    source={element.source}
                                    urlToShare={`https://hulchal.in/news/${element.category}/${btoa(element.id)}/${element.title.replace(/ /g, '-')}`} 
                                    id={element.id} 
                                />
                            </div>
                        );
                    })}
                    </div>
                </div>
            </InfiniteScroll>
        </>
        </HelmetProvider>
    )
}

News.defaultProps = {
    country: 'in',
    category: 'sport',
    pageSize: 6
}

News.propTypes = {
    country: PropTypes.string,
    category: PropTypes.string,
    pageSize: PropTypes.number
}

export default News;
