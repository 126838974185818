import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Button,IconButton } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Facebook, LinkedIn, Instagram, WhatsApp } from '@mui/icons-material'; // Importing icons

const NewsItemsDetail = (props) => {
  const [queryData, setQueryData] = useState({});

  const fetchNewsData = async () => {
    //props.setProgress(0);
    var abcT = '';
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    for (const [key, value] of queryParams.entries()) {
      if (key === 'news') {
        abcT = atob(value);
      }
    }
    //let url = 'http://localhost/test/getnews.php';
    const url = 'https://news.g1tech.in/getnews';
    const postData = {
      title: abcT,
      isSingle: 1
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    };
    try {
      const newsData = await fetch(url, options);
      const parsedData = await newsData.json();
      const queryParams = parsedData[0];
      console.log('parsedData', queryParams);
      setQueryData(queryParams);
      document.title = `${queryParams.category} - G1News `;
      //props.setProgress(100);
    } catch (error) {
      console.error('Error fetching news data:', error);
    }
  };
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(newsUrl)}`, '_blank');
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(newsUrl)}&title=${encodeURIComponent(title)}`, '_blank');
  };

  const shareOnInstagram = () => {
    alert('Instagram sharing not supported directly via web. Please share manually.');
  };

  // Function to handle sharing on WhatsApp
  const shareOnWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(title + '\n' + newsUrl)}`, '_blank');
  };
  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: queryData.title,
          text: queryData.description,
          url: window.location.href
        });
        const platform = prompt('Where did you share this? (e.g., Facebook, Twitter, etc.)');
        if (platform) {
          //logShare(window.location.href, platform);
        }
      } else {
        console.log('Web Share API not supported.');
      }
    } catch (error) {
      console.error('Error sharing content:', error);
    }
  };
  

  useEffect(() => {
    fetchNewsData();
  }, []);

  let { title, description, imageUrl, newsUrl, auther, updateDate, source } = queryData;
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={description? description.split(' ').join(', '):''} />
      </Helmet>
      <Card className='container'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <CardMedia
              component="img"
              alt={title}
              height="100%"
              src={imageUrl}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <CardContent>
              <Typography variant="h5" component="div">
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {description}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                By {auther} on {updateDate} | Source: {source}
              </Typography>
              <Button
                href={newsUrl}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                color="primary"
                size="small"
              >
                Read More
              </Button>
              <Button
                onClick={handleShare} // Change to Button from MUI
                variant="contained"
                color="secondary"
                size="small"
                style={{ marginLeft: '8px' }} // Adjust spacing
              >
                Share
              </Button>
              <IconButton
          onClick={shareOnFacebook}
          color="primary"
          size="small"
          style={{ marginLeft: '8px' }}
        >
          <Facebook />
        </IconButton>
        <IconButton
          onClick={shareOnLinkedIn}
          color="primary"
          size="small"
          style={{ marginLeft: '8px' }}
        >
          <LinkedIn />
        </IconButton>
        <IconButton
          onClick={shareOnInstagram}
          color="primary"
          size="small"
          style={{ marginLeft: '8px' }}
        >
          <Instagram />
        </IconButton>
        <IconButton
          onClick={shareOnWhatsApp}
          color="primary"
          size="small"
          style={{ marginLeft: '8px' }}
        >
          <WhatsApp />
        </IconButton>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default NewsItemsDetail;
