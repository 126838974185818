import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Alert } from '@mui/material';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [alert, setAlert] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const postData = {
            action: 'send_mail',
            name: formData.name,
            email: formData.email,
            message: formData.message
        };

        try {
            const response = await fetch('https://hulchal.in/apis/send_mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            const result = await response.json();
            console.log('resultresult',result);
            if (result.status=='success') {
                setAlert({ type: 'success', message: 'Thank you for reaching out to us! We have received your message and will get back to you as soon as possible.' });
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
            } else {
                setAlert({ type: 'error', message: result.error || 'Failed to send your message. Please try again later.' });
            }
        } catch (error) {
            setAlert({ type: 'error', message: 'Failed to send your message. Please try again later.' });
        }
    };

    return (
        <Container>
            <Box my={4}>
                <Typography variant="h3" component="h1" gutterBottom>
                    Contact Us
                </Typography>
                {alert && <Alert severity={alert.type}>{alert.message}</Alert>}
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        required
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default ContactUs;
