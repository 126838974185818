import React from 'react'
import { Link} from 'react-router-dom';
import { Grid, Card, CardContent, CardMedia, Typography, Button, IconButton } from '@mui/material';
import { Facebook, LinkedIn, Instagram, WhatsApp } from '@mui/icons-material'; // Importing icons

const NewsItems =(props) => {
  let {title,id, description,category, imageUrl,newsUrl,auther,updateDate,source,urlToImage,urlToShare} = props;
  
  const isYouTubeLink = (url) => {
      const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|shorts)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      return youtubeRegex.test(url);
  };
  const getYouTubeVideoId = (url) => {
      const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|shorts)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
      return match ? match[1] : null;
  };
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`,  'facebook-share-dialog', 'width=800,height=600');
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(urlToShare)}&title=${encodeURIComponent(title)}`, '_blank');
  };

  const shareOnInstagram = () => {
    alert('Instagram sharing not supported directly via web. Please share manually.');
  };

  // Function to handle sharing on WhatsApp
  const shareOnWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(urlToShare)}`, '_blank');
  };
  const handleShare = async () => {
    try {
        if (navigator.share) {
            await navigator.share({
                title: title.replace(/ /g, '-'),
                url: urlToShare
            });
            console.log('Content shared successfully.');
        } else {
            console.log('Web Share API not supported.');
        }
    } catch (error) {
        console.error('Error sharing content:', error);
    }
};
  return (
    <div key={`news_item_${id}`} >
            <div key={`news_item1_${id}`} className="card" >
            <a href={urlToShare}>
            {isYouTubeLink(imageUrl) ? (
                <iframe
                    key={`news_item2_${id}`}
                    width="100%"
                    height="200"
                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(imageUrl)}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube video"
                ></iframe>
            ) : (
                <img key={`news_item2_${id}`} src={imageUrl} rel="noreferrer" className="card-img-top" alt="/" />
            )}
            </a>
            <div className="card-body" key={`news_item3_${id}`} >
                <h5 className="card-title" key={`news_item4_${id}`} >
                <a className="nav-link"
                    href={urlToShare}
                    rel="noreferrer"
                    >{title}
                </a>
                <span key={`news_item5_${id}`}  className='position-absolute top-0 translate-middle badge bg-danger rounded-pill' style={{left:'90%', zIndex:'1'}}>{source}</span></h5>
                <p key={`news_item6_${id}`}  className="card-text">{description}</p>
                <p key={`news_item7_${id}`}  className="card-text"><small className='text-muted'>By {auther} on {updateDate}</small></p>
                <a target='_blank'
                    className="btn btn-sm btn-primary"
                    href={urlToShare}
                    rel="noreferrer">Read More
                </a>
                <Button
                onClick={handleShare} // Change to Button from MUI
                variant="contained"
                color="secondary"
                size="small"
                style={{ marginLeft: '8px' }} // Adjust spacing
              >
                Share
              </Button>
              <IconButton
          onClick={shareOnFacebook}
          color="primary"
          size="small"
          style={{ marginLeft: '8px' }}
        >
          <Facebook />
        </IconButton>
        <IconButton
          onClick={shareOnLinkedIn}
          color="primary"
          size="small"
          style={{ marginLeft: '8px' }}
        >
          <LinkedIn />
        </IconButton>
        <IconButton
          onClick={shareOnInstagram}
          color="primary"
          size="small"
          style={{ marginLeft: '8px' }}
        >
          <Instagram />
        </IconButton>
        <IconButton
          onClick={shareOnWhatsApp}
          color="primary"
          size="small"
          style={{ marginLeft: '8px' }}
        >
          <WhatsApp />
        </IconButton>
            </div>
            </div>
      </div>
    )
}
export default NewsItems